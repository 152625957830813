import React, { Component } from 'react';

import Koncert from "./Koncert";

import koncert1 from '../../images/koncert1.png';
import koncert2_bdp from '../../images/koncert2_bpd_thumbnail.png';
import logo from '../../images/logo_koncertek.png';

import './HungarikumKoncertek.scss';
import ModuleTitle from "./ModuleTitle";

import {withTranslation} from "react-i18next";
import {FancyButton} from "../../common/Button";

class HungarikumKoncertek extends Component {
    render() {
        const {t} = this.props;
        return (
            <div id="koncertek-wrapper">
                <ModuleTitle title={t('koncertek.kovetkezoKoncertek')}/>
                <Koncert picture={logo} date={t('koncertek.datum')} place={t('koncertek.btArena')}>
                    <h2>{t('koncertek.unnepiKoncert')}&nbsp; - &nbsp;<span className="koszonjuk">{t('koncertek.koszonjukKolozsvar')}</span></h2>
                    <p className="montserrat-font koncertek-button-holder">
                        <FancyButton label={t('koncertek.kolozsvar2023')} url="/events/kolozsvar2023" type="HUNG_ORANGE"/>
                    </p>
                </Koncert>
                {/*<Koncert picture={logo} date={t('koncertek.kovetkezik')} place={t('koncertek.online')} online={true}>*/}
                {/*    <h2>{t('koncertek.unnepiKoncert')} {t('koncertek.eloKozvetites')}</h2>*/}
                {/*</Koncert>*/}
                <Koncert picture={koncert2_bdp} date={t('koncertek.datumBudapest')} place={t('koncertek.mvmDome')}>
                    <h2>{t('koncertek.unnepiKoncert')}</h2>
                    <p className="montserrat-font koncertek-button-holder">
                        <FancyButton label={t('koncertek.tickets')} target="_blank"
                                     url="https://www.eventim.hu/hu/jegyek/hungarikum-kavalkad-budapest-mvm-dome-658238/event.html" type="HUNG_ORANGE"/>
                    </p>
                    {/*<h2>{t('koncertek.szervezesAlatt')}</h2>*/}
                </Koncert>
                {/*<Koncert picture={logo} date={t('koncertek.kovetkezik')} place={t('koncertek.kanada')}>*/}
                {/*    <h2>{t('koncertek.szervezesAlatt')}</h2>*/}
                {/*</Koncert>*/}
            </div>
        )
    }
}

export default withTranslation()(HungarikumKoncertek);